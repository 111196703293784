<template>
  <div id="market-results" class="container">
    <div class="page-header mb-1">
      <strong>ผลการออกรางวัล</strong>
    </div>

    <b-card no-body class="mb-1 position-relative p-2">
      <div class="d-flex justify-content-center">
        <div class="input-group my-4" style="width: 250px;">
          <div class="input-group-prepend">
            <button class="btn btn-secondary" type="button" @click="prevDate"><i class="fas fa-chevron-left"></i></button>
          </div>
          <flat-pickr
            v-model="date"
            :config="datePickerConfig"
            class="form-control text-center border-secondary font-weight-bold text-primary"
          ></flat-pickr>
          <div class="input-group-append">
            <button class="btn btn-secondary" type="button" @click="nextDate"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
      <table class="table table-pp table-bordered table-hover table-sm mb-0">
        <thead>
          <tr>
            <th width="30%">ชนิดหวย</th>
            <th width="25%">งวด</th>
            <th width="15%">3 ตัวบน</th>
            <th width="15%">2 ตัวบน</th>
            <th width="15%">2 ตัวล่าง</th>
          </tr>
        </thead>
        <tbody v-for="group in groups">
          <tr>
            <th colspan="5" class="text-primary">{{group.group.groupTitle}}</th>
          </tr>
          <tr v-for="market in group.markets">
            <td>{{market.note.marketTitle}}</td>
            <td class="text-center">{{ market.roundDate.date | dispDateTime("DD-MM-YYYY") }}</td>
            <td v-if="market.marketId.marketType === 'single'" class="text-center">{{ market | result('threeNumberTop') }}</td>
            <td v-if="market.marketId.marketType === 'single'" class="text-center">{{ market | result('twoNumberTop') }}</td>
            <td v-if="market.marketId.marketType === 'single'" class="text-center">{{ market | result('twoNumberBottom') }}</td>
            <td v-if="market.marketId.marketType === 'lottoset'" class="text-center" colspan="3">{{ market | result('fourNumber') }}</td>
          </tr>
        </tbody>
        <tbody v-if="!isLoading && !groups.length">
          <tr>
            <td colspan="5" class="text-center">ไม่มีงวดหวย</td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>
<script>
import RoundService from "@/services/RoundService"
import Swal from 'sweetalert2'
import _ from 'lodash'
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import {Thai as ThaiLocale} from 'flatpickr/dist/l10n/th.js'

export default {
  name: 'MarketResults',
  components: {
    flatPickr
  },
  data() {
    return {
      data: null,
      isLoading: false,
      date: moment().format("YYYY-MM-DD"),
      datePickerConfig: {
        locale: ThaiLocale,
        maxDate: moment().format("YYYY-MM-DD")
      },
    }
  },
  computed: {
    marketGroups() {
      return this.$store.state.marketGroups
    },
    markets() {
      return this.$store.state.markets
    },
    groups() {
      if(!this.data)
        return []

      const items = _.orderBy(this.data.map((item)=>{
        item.market = this.markets.find((m)=>{
          return item.marketId === m._id
        })
        return item
      }), ['roundDate.close'], ['asc'])

      const groups = _.groupBy(items, (round)=>{
        return round.groupId
      })

      const rounds = []
      for(const groupId in groups) {
        rounds.push({
          group: this.marketGroups.find((g)=>{
            return g._id === groupId
          }),
          markets: groups[groupId]
        })
      }

      return _.orderBy(rounds, ['group.sort'], ['asc'])
    }
  },
  watch: {
    date(n, o) {
      if(n)
        this.getResults()
    }
  },
  methods: {
    getResults() {
      this.isLoading = true
      RoundService.getResults(this.date)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    prevDate() {
      this.date = moment(this.date).add(-1, 'd').format("YYYY-MM-DD")
    },
    nextDate() {
      this.date = moment(this.date).add(1, 'd').format("YYYY-MM-DD")
    }
  },
  mounted() {
    this.getResults()
  },
  filters: {
    result(market, type) {

      if(market.status === 'Cancel') {
        return 'ยกเลิก'
      }else
      if(market.status === 'Refund') {
        return 'คืนเงิน'
      }

      if(!market?.results[type])
        return ''

      return market.results[type].join('-')
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
      }
    }
  }
  tbody {
    background-color: #FFF;
  }
}
</style>
